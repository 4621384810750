<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
      <path class="share-link"
        :class="isClicked ? 'fill' : 'no-fill'"
        d="M3226-2118a13.906,13.906,0,0,1-9.9-4.1,13.909,13.909,0,0,1-4.1-9.9,13.909,13.909,0,0,1,4.1-9.9,13.908,13.908,0,0,1,9.9-4.1,13.91,13.91,0,0,1,9.9,4.1,13.908,13.908,0,0,1,4.1,9.9,13.909,13.909,0,0,1-4.1,9.9A13.908,13.908,0,0,1,3226-2118Zm-1.05-16.7a3.126,3.126,0,0,0-2.2.914l-2.837,2.839a3.1,3.1,0,0,0-.913,2.2,3.1,3.1,0,0,0,.913,2.208,3.1,3.1,0,0,0,2.207.916h.011a3.077,3.077,0,0,0,2.194-.91l2.342-2.342a.224.224,0,0,0,.067-.159.224.224,0,0,0-.223-.221h-.1a3.756,3.756,0,0,1-1.42-.277.219.219,0,0,0-.084-.017.226.226,0,0,0-.159.066l-1.684,1.686a1.327,1.327,0,0,1-.946.392,1.33,1.33,0,0,1-.947-.392,1.33,1.33,0,0,1-.392-.947,1.33,1.33,0,0,1,.392-.947l2.848-2.845a1.33,1.33,0,0,1,.946-.391,1.328,1.328,0,0,1,.945.391.914.914,0,0,0,.627.249.911.911,0,0,0,.626-.249.9.9,0,0,0,.258-.567.892.892,0,0,0-.258-.687,3.047,3.047,0,0,0-.524-.421,3.117,3.117,0,0,0-1.684-.493Zm-.111,2.365a.914.914,0,0,0-.627.249.9.9,0,0,0-.258.571.9.9,0,0,0,.258.688,3.075,3.075,0,0,0,.893.624c.034.016.068.03.1.044l.039.016c.027.012.054.022.081.032l.012,0,.049.018c.039.015.084.03.143.047l.132.036c.087.021.175.039.27.055a2.949,2.949,0,0,0,.332.031h.169l.134-.015.051,0h.011a.779.779,0,0,1,.1-.008h.076l.154-.022.072-.013.129-.026h.025a3.124,3.124,0,0,0,1.447-.821l2.839-2.84a3.1,3.1,0,0,0,.915-2.207,3.1,3.1,0,0,0-.915-2.208,3.1,3.1,0,0,0-2.208-.915,3.1,3.1,0,0,0-2.208.915l-2.339,2.337a.223.223,0,0,0-.048.246.224.224,0,0,0,.206.136h.094a3.729,3.729,0,0,1,1.417.278.224.224,0,0,0,.085.017.224.224,0,0,0,.158-.066l1.678-1.677a1.33,1.33,0,0,1,.947-.392,1.33,1.33,0,0,1,.947.392,1.327,1.327,0,0,1,.392.946,1.328,1.328,0,0,1-.392.947l-2.092,2.09-.018.02-.732.727a1.328,1.328,0,0,1-.946.391,1.328,1.328,0,0,1-.945-.391A.912.912,0,0,0,3224.839-2132.333Z"
        transform="translate(-3212 2146)"
      />
    </svg>
  </div>
</template>

<script>
  export default {
    props: ["isClicked"],
    data() {
      return {}
    }
  };
</script>

<style>
svg{
  display:block;
  width:100%;
}
</style>