<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
      <g transform="translate(0)">
        <g transform="translate(0)">
          <g transform="translate(0 0)">
            <g transform="translate(0)">
              <circle cx="14" cy="14" r="14" class="share-link" :class="isClicked ? 'fill' : 'no-fill'" />
              <path
                class="b"
                d="M15.962,4.865V7.373H14.471c-1.168,0-1.387.56-1.387,1.368v1.8h2.783l-.37,2.812H13.084v7.209H10.178V13.348H7.755V10.536h2.422V8.465A3.389,3.389,0,0,1,13.8,4.751,18.614,18.614,0,0,1,15.962,4.865Z"
                transform="translate(2.053 1.258)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: ["isClicked"],
  data() {
    return {
      changeColor: "#ec0f85",
      defaultColor: "#F7F7F799"
    };
  }
};
</script>

<style>

svg{
  display:block;
  width:100%;
}
.b {
  fill: #141919;
  fill-rule: evenodd;
}
</style>